import { z } from "zod";

import { ReviewStatus } from "../../status";

export const editableClaimFields = [
  { name: "fileId", type: "radio", required: false },
  { name: "reviewStatus", type: "dropdown", required: true },
  { name: "practiceId", type: "dropdown", required: true },
  { name: "payer", type: "dropdown", required: true },
  { name: "wieldyId", type: "text", required: true },
  { name: "wieldyClaimDate", type: "date", required: true },
  { name: "claimPayerPays", type: "number", required: true },
  { name: "patientFullName", type: "text", required: false },
  { name: "claimId", type: "text", required: true },
  { name: "claimType", type: "text", required: false },
  { name: "payerPatientIdPart1", type: "text", required: true },
  { name: "payerPatientIdPart2", type: "text", required: false },
  { name: "patientDob", type: "date", required: false },
  { name: "patientPayerNetwork", type: "text", required: false },
  { name: "claimPriorPaidAmount", type: "number", required: false },
  { name: "claimGroupNumber", type: "text", required: false },
  { name: "claimGroupName", type: "text", required: false },
  { name: "claimLineItem", type: "number", required: false },
  { name: "claimLineItemDescription", type: "text", required: false },
  { name: "lifetimeBenefitUsed", type: "number", required: false },
  { name: "providerId", type: "text", required: false },
  { name: "relationshipToSubscriber", type: "text", required: false },
  { name: "subscriberId", type: "text", required: false },
] as const;

export const defaultClaimFields = {
  claimType: "CLAIM",
  reviewStatus: ReviewStatus.REVIEW,
};

export const claimFormSchemaBase = z.object(
  editableClaimFields.reduce((acc: Record<string, z.ZodType>, field) => {
    if (field.type === "text") {
      acc[field.name] = field.required
        ? z.string().min(3, `${field.name} must be at least 3 characters long`)
        : z.string().optional();
    } else if (field.type === "number") {
      acc[field.name] = field.required ? z.number() : z.number().optional();
    } else if (field.type === "date") {
      acc[field.name] = field.required
        ? z.coerce.date().transform((date) => date.toISOString().split("T")[0])
        : z.coerce
            .date()
            .transform((date) => date.toISOString().split("T")[0])
            .optional();
    } else if (["dropdown", "radio"].includes(field.type)) {
      acc[field.name] = field.required
        ? z.string().min(1, `${field.name} is required`)
        : z.string().optional();
    }
    return acc;
  }, {}),
);

export type AddClaimFormFields = z.infer<typeof claimFormSchemaBase>;

export const editableProcedureFields = [
  { name: "practiceId", type: "dropdown", required: true },
  { name: "wieldyId", type: "text", required: true },
  { name: "procedureDate", type: "date", required: true },
  { name: "procedureCode", type: "text", required: true },
  { name: "procedurePayerPays", type: "number", required: true },
  { name: "procedureContractedAmount", type: "number", required: true },
  { name: "procedureDeductibleAmount", type: "number", required: true },
  { name: "procedurePatientCoinsurance", type: "number", required: true },
  { name: "procedurePatientCopay", type: "number", required: true },
  { name: "procedurePatientNoncovered", type: "number", required: false },
  { name: "procedurePatientResponsibility", type: "number", required: true },
  { name: "procedureSubmittedAmount", type: "number", required: true },
  { name: "procedurePayerWriteoff", type: "number", required: false },
  { name: "procedureStatus", type: "text", required: false },
  { name: "procedureToothNumber", type: "text", required: false },
  { name: "procedureDenialCode", type: "text", required: false },
  { name: "procedureDenialDescription", type: "text", required: false },
] as const;

export const defaultProcedureFields = {};

export const procedureFormSchemaBase = z.object(
  editableProcedureFields.reduce((acc: Record<string, z.ZodType>, field) => {
    if (field.type === "text") {
      acc[field.name] = field.required
        ? z.string().min(3, `${field.name} must be at least 3 characters long`)
        : z.string().optional();
    } else if (field.type === "number") {
      acc[field.name] = field.required ? z.number() : z.number().optional();
    } else if (field.type === "date") {
      acc[field.name] = field.required
        ? z.coerce.date().transform((date) => date.toISOString().split("T")[0])
        : z.coerce
            .date()
            .transform((date) => date.toISOString().split("T")[0])
            .optional();
    } else if (field.type === "dropdown") {
      acc[field.name] = z.string().min(1, `${field.name} is required`);
    }
    return acc;
  }, {}),
);

export type ProcedureFormFields = z.infer<typeof procedureFormSchemaBase>;

import {
  DataGridPro,
  gridClasses,
  GridRowSpacingParams,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import React from "react";
import { useLocation } from "react-router-dom";

import useDataGridPersistence from "../../utils/datagrid";
import useDevMode from "../../utils/devmode";
import { sortAlphabetically, toValueOptions } from "../../utils/utils";
import CustomNoRowsOverlay from "../TableNoDataOverlay";
import { COLUMN_DEFAULT_VISIBILITY, getDefaultColumns } from "./columns";

interface ReconciliationsTableProps {
  isLoading: boolean;
  transactions: BankTransactionMessage[];
  updateTransaction: (
    transactionUpdate: BankTransactionUpdateMessage,
  ) => Promise<void>;
  persistKey: string;
  rowCount?: number;
}

export default function ReconciliationsTable({
  isLoading,
  transactions,
  updateTransaction,
  rowCount,
  persistKey,
}: ReconciliationsTableProps) {
  const getRowSpacing = React.useCallback(
    (params: GridRowSpacingParams) => ({
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    }),
    [],
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dataGridPersistence = useDataGridPersistence(persistKey);
  const devMode = useDevMode();

  const columns = getDefaultColumns(
    toValueOptions(
      transactions.map((t) => ({ practice: t.practice })),
      "practice",
      sortAlphabetically,
    ),
    searchParams,
    updateTransaction,
  );

  return (
    <div
      style={
        isLoading || (isLoading && !transactions.length)
          ? { height: "25rem" }
          : {}
      }
    >
      <DataGridPro
        loading={isLoading}
        apiRef={dataGridPersistence.apiRef}
        rowHeight={72}
        getRowSpacing={getRowSpacing}
        rows={transactions}
        getRowId={(row) => row.wieldyId}
        disableRowSelectionOnClick
        columns={columns}
        pageSizeOptions={[10, 20, 40]}
        pagination
        rowCount={rowCount}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: GridToolbar,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          sorting: {
            sortModel: [{ field: "bankDate", sort: "desc" }],
          },
          columns: {
            columnVisibilityModel: COLUMN_DEFAULT_VISIBILITY,
          },
          ...(dataGridPersistence?.retrieveState(devMode) ?? {}),
        }}
        sx={{
          [`& .${gridClasses.columnHeader}:first-child:focus, & .${gridClasses.cell}:first-child:focus`]:
            {
              borderTopLeftRadius: "12px",
              borderBottomLeftRadius: "12px",
            },
          [`& .${gridClasses.columnHeader}:last-child:focus, & .${gridClasses.cell}:last-child:focus`]:
            {
              borderTopRightRadius: "12px",
              borderBottomRightRadius: "12px",
            },
        }}
      />
    </div>
  );
}
